<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      :heading="dealerGroup"
    />
    <v-row>
      <v-col cols="4">
        <v-btn
          color="success"
          fab
          x-large
          dark
          href="https://dealer-insights-api.herokuapp.com/download_sales_dar_pdf?dgid=bf8ebfb3-e850-4586-8ca7-de35f06a3d97"
          target="_blank"
        >
          <v-icon>mdi-file-pdf</v-icon>
        </v-btn>
        Download Daily Sales DAR
      </v-col>
      <v-col cols="4">
        <v-btn
          color="error"
          fab
          x-large
          dark
          href="https://dealer-insights-api.herokuapp.com/download_sales_dar_summary_pdf?dgid=bf8ebfb3-e850-4586-8ca7-de35f06a3d97"
          target="_blank"
        >
          <v-icon>mdi-file-pdf</v-icon>
        </v-btn>
        Download Monthly Sales DAR Summary
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-btn
          color="success"
          fab
          x-large
          dark
          href="https://dealer-insights-api.herokuapp.com/download_service_dar_pdf?dgid=bf8ebfb3-e850-4586-8ca7-de35f06a3d97"
          target="_blank"
        >
          <v-icon>mdi-file-pdf</v-icon>
        </v-btn>
        Download Daily Service DAR
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <template>
          <v-card
            max-width="500"
            class="mx-auto"
          >
            <v-toolbar
              color="teal"
              dark
            >
              <v-toolbar-title>Stores Missing Sales DAR</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-list>
              <v-list-item
                v-for="item in missing_sale_dars"
                :key="item"
              >
                <v-list-item-icon>
                  <v-icon
                    v-if="item.icon"
                    color="pink"
                  >
                    mdi-star
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </template>
      </v-col>
      <v-col cols="4">
        <template>
          <v-card
            max-width="500"
            class="mx-auto"
          >
            <v-toolbar
              color="teal"
              dark
            >
              <v-toolbar-title>Stores Missing Service DAR</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-list>
              <v-list-item
                v-for="item in missing_service_dars"
                :key="item"
              >
                <v-list-item-icon>
                  <v-icon
                    v-if="item.icon"
                    color="pink"
                  >
                    mdi-star
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <template v-if="uploading">
        <v-progress-linear
          v-model="progress"
          color="light-blue"
          height="25"
          reactive
          striped
        >
          <strong>{{ progress }} %</strong>
        </v-progress-linear>
      </template>

      <!-- <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              Upload Stone Eagle Service Import Files
            </v-card-title>
            <template>
              <v-file-input
                show-size
                label="Click to select files to upload."
                accept=".xlsx"
                @change="selectFile"
              />
            </template>
            <v-row dense>
              <v-col class="text-right">
                <v-btn
                  color="primary"
                  :disabled="file === undefined || file == '' || uploading"
                  @click="handleFileUpload()"
                >
                  Upload
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              Upload Stone Eagle Sales Import Files
            </v-card-title>
            <template>
              <v-file-input
                show-size
                label="Click to select files to upload."
                accept=".xlsx"
                @change="selectFile"
              />
            </template>
            <v-row dense>
              <v-col class="text-right">
                <v-btn
                  color="primary"
                  :disabled="file === undefined || file == '' || uploading"
                  @click="handleSalesUpload()"
                >
                  Upload
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              Upload Stone Eagle Service Import Files
            </v-card-title>
            <template>
              <v-file-input
                show-size
                label="Click to select files to upload."
                accept=".xlsx"
                @change="selectFile"
              />
            </template>
            <v-row dense>
              <v-col class="text-right">
                <v-btn
                  color="primary"
                  :disabled="file === undefined || file == '' || uploading"
                  @click="handleServiceUpload()"
                >
                  Upload
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import DarService from '@/services/DarService.js'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'

  export default {
    name: 'DarStatus',
    mixins: [formatterMixin],
    data () {
      return {
        loading: false,
        missing_sale_dars: [],
        missing_service_dars: [],
        error: '',
        progress: 0,
        files: [],
        uploading: false,
        file: '',
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName', 'dealerGroup']),
    },
    created () {
      this.loadDarSummary()
    },
    methods: {
      loadDarSummary: function () {
        this.loading = true
        var data = {
          dgid: this.$route.query.dgid,
        }
        DarService.getDarStatus(data)
          .then(response => {
            this.loading = false
            this.missing_sale_dars = response.data.missing_sale_dars
            this.missing_service_dars = response.data.missing_service_dars
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              if (errorMessage) {
                this.error = errorMessage
              } else {
                this.error = 'Uh oh! There was a problem loading the page. Our support department has been contacted.'
              }
            }
          })
      },
      handleFileUpload () {
        this.uploading = true
        this.progress = 1
        DarService.uploadSeServiceFix(this.file, (event) => {
        })
          .then((response) => {
            this.progress = 100
            this.file = undefined
            this.uploading = false
          })
          .catch(() => {
            this.progress = 0
            this.file = undefined
            this.uploading = false
          })
      },
      handleSalesUpload () {
        this.uploading = true
        this.progress = 1
        DarService.uploadSalesFile(this.file, (event) => {
        })
          .then((response) => {
            this.progress = 100
            this.file = undefined
            this.uploading = false
          })
          .catch(() => {
            this.progress = 0
            this.file = undefined
            this.uploading = false
          })
      },
      handleServiceUpload () {
        this.uploading = true
        this.progress = 1
        DarService.uploadServiceFile(this.file, (event) => {
        })
          .then((response) => {
            this.progress = 100
            this.file = undefined
            this.uploading = false
          })
          .catch(() => {
            this.progress = 0
            this.file = undefined
            this.uploading = false
          })
      },
      selectFile (file) {
        this.progress = 0
        this.file = file
      },
    },
  }
</script>
